<template>
  <v-hover>
    <v-card slot-scope="{ hover }" :class="`elevation-${hover ? 8 : 2 }`" class="mx-auto">
      <v-card-title class="blue-grey lighten-4">
        <v-row no-gutters>
          <v-col :cols="10">
            <div class="title text--primary text-truncate"
              style="width: 100%;">{{ control.friendlyName }}</div>

            <div class="subtitle-1 text--secondary font-weight-light"
              style="width: 100%;">{{ control.site.name }}</div>
          </v-col>
          <v-col align-self="center">
            <v-icon color="white" right>fa-battery-three-quarters</v-icon>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text>
        <section class="mb-4">
          <div>Energy Capacity</div>
          <v-progress-linear :color="getBatteryColor()" class="mt-2" height="7" rounded :value="getBatteryPercentage(control.data) * 100" />
          <div>{{ Math.round(getBatteryPercentage(control.data) * 100) }}%</div>
        </section>

        <section class="mb-4">
          <div>Discharge Rate</div>
          <hightcharts :options="getDischargeRateChartOptions()" />
        </section>

        <Label label="Energy Capacity" :value="`${control.data.capacity} kW`" single-line />
        <Label label="Peak Discharge" :value="`${control.data.peakDischargeRate} kW`" single-line />
        <Label label="Stack Voltage" :value="`${control.data.stackVoltage} V`" single-line />
        <Label label="Stack Temperature" :value="`${control.data.stackTemperature} C`" single-line />
        <Label label="Peak Current" :value="`${control.data.peakCurrent} A`" single-line />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-menu offset-x>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              icon
              v-on="on"
              title="Strategies">
              <v-icon>wb_incandescent</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item-group multiple>
              <v-list-item v-for="strategy in strategies" :key="strategy.id" @click="selectStrategy">
                <template>
                  <v-list-item-action>
                    <v-checkbox color="primary" v-model="selectedStrategies[strategy.id]"></v-checkbox>
                  </v-list-item-action>
                  <v-list-item-content>
                    <v-list-item-title>{{ strategy.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import Label from "./Label";
import Highcharts from "highcharts";
// import exportingInit from "highcharts/modules/exporting";
import loadHighchartsMore from 'highcharts/highcharts-more.js';
import loadSolidGauge from 'highcharts/modules/solid-gauge.js';
import { Chart }  from "highcharts-vue";

loadHighchartsMore(Highcharts);
loadSolidGauge(Highcharts);

export default {
  components: { hightcharts: Chart, Label },
  props: {
    control: Object
  },

  data() {
    return {
      strategies: [
        { id: 1, name: "Demand Management" },
        { id: 2, name: "Peak Management" },
        { id: 3, name: "Power Factor Correction" }
      ],
      selectedStrategies: []
    }
  },

  methods: {
    selectStrategy() {
      console.log("Selected Strategy");
    },

    getBatteryColor() {
      let { capacity, currentCapacity } = this.control.data;
      let batteryPercentage = this.getBatteryPercentage({ capacity: capacity, currentCapacity: currentCapacity });
      if (batteryPercentage >= 0.6) return "green";
      else if (batteryPercentage >= 0.4) return "orange";
      else return "red";
    },

    getBatteryPercentage(params) {
      let { capacity, currentCapacity } = params;
      return currentCapacity / capacity;
    },

    getDischargeRateChartOptions() {
      let gaugeOptions = {
        chart: {
          type: 'solidgauge',
          height: 200
        },

        title: null,

        pane: {
          center: ['50%', '45%'],
          
          startAngle: -90,
          endAngle: 90,
          background: {
            backgroundColor: Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
            innerRadius: '60%',
            outerRadius: '100%',
            shape: 'arc'
          }
        },

        tooltip: {
          enabled: false
        },

        plotOptions: {
          solidgauge: {
            dataLabels: {
              y: 5,
              borderWidth: 0,
              useHTML: true
            }
          }
        },

        yAxis: {
          min: -60,
          max: 60,
          title: {
            text: 'kW'
          }
        },

        credits: {
          enabled: false
        },

        series: [{
          name: 'Current Discharge Rate',
          data: [this.control.data.dischargeRate],
          dataLabels: {
            format:
              '<div style="text-align:center">' +
              '<span style="font-size:25px">{y}</span><br/>' +
              '<span style="font-size:12px;opacity:0.4">kW</span>' +
              '</div>'
          },
          tooltip: {
            valueSuffix: ' kW'
          }
        }]
      };

      return gaugeOptions;
    }
  }
}
</script>