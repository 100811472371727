const STORE_KEY = "$_controls";
const refreshInterval = 120000; //2 minutes  

const CONTROL_UPDATED = (state, control) => {
  let timestamp = Date.now();
  console.log(`control set ${control.id}, timestamp: ${timestamp}`)
  let payload = {updated: timestamp, control: control}
 
  upsertControlUsage(state, payload)

  console.log(state.updatedControls)

  state.controlsUpdated = timestamp;
  console.log(`${control.friendlyName} control usage updated in store`)
  
  //store the updated cached control usages
  localStorage.setItem(STORE_KEY, JSON.stringify(state));
};


const CONTROLS_REFRESHED = (state, controls) => {
  const currentTimestamp = Date.now();
  //remove stale updates and refresh state version
  const filteredUpdated = state.updatedControls.filter((c) => {
       if (currentTimestamp - c.updated <= refreshInterval) {
         return c;
       }
  })

  state.updatedControls = filteredUpdated;

  //replace controls with freshly updated controls
  let controlsWithCachedValues = replaceControls(controls, filteredUpdated)

  //update state collection of controls --  TODO: we might get rid of this one
  state.controls = upsertControlTypes(state.controls, controlsWithCachedValues);

  //update state collection of refreshedControls
  state.refreshedControls = state.controls;
  
  //store the updated cached control usages
  localStorage.setItem(STORE_KEY, JSON.stringify(state));

}

const INITIALIZE_STORE = async (context, state) => {
  if (localStorage.getItem(STORE_KEY)) {
    Object.assign(context, JSON.parse(localStorage.getItem(STORE_KEY)))
    console.log('store updated with state:' + state)
  }
}

export default {
  CONTROL_UPDATED,
  CONTROLS_REFRESHED,
  INITIALIZE_STORE
};


function upsertControlUsage(state, controlPayload) {
  const index = state.updatedControls.findIndex((x) => x.control.id === controlPayload.control.id);

  const newControls = state.updatedControls.map((c, ndx) => {
    if (index === ndx) {
      return controlPayload;
    } else {
      return c;
    }
  });

  if (index >= 0) {
    state.updatedControls = newControls;
  } else {
    state.updatedControls.push(controlPayload)
  }
}

function replaceControls(controls, updatedControls) {
  const newControls = controls.controls.map((c) => {
    const index = updatedControls.findIndex((x) => x.control.id === c.id);

    if (index >= 0) {
      return updatedControls[index].control;
    } else {
      return c;
    }
  });

  controls.controls = newControls
  return controls;   
}

function upsertControlTypes(controls, updatedControls) {
  const index = controls.findIndex((x) => x.type === updatedControls.type);

  const newControls = controls.map((c, ndx) => {
    if (index === ndx) {
      return updatedControls;
    } else {
      return c;
    }
  });

  if (index >= 0) {
    controls = newControls;
  } else {
    controls.push(updatedControls)
  }

  return controls;
}
