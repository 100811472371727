export const getControlById = async (controlType, controlId) => {
    let result = null;
    try {
        if (controlType === "thermostat") {
            result = await window.axios.get(`/v1/thermostat/${controlId}`);
            return result.data.data;
        } else if (controlType === "powercontrol") {
            console.log("here")
            result = await window.axios.get(`/v1/powercontrol/${controlId}`);
            return result.data.data;
        } else {
            return null;
        }
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};

export const updateControl = async(controlType, updatedControl) => {
    let result = null;
    try {
        if (controlType === "thermostat") {
            result = await window.axios.put('/v1/thermostat/update', updatedControl);
            return result.data.data;
        } else if (controlType === "powercontrol") {
            result = await window.axios.put('/v1/powercontrol/update', updatedControl);
            return result.data.data;
        } else {
            return null;
        }
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
};

export const deleteControl = async(controlType, controlId) => {
    let result = null;
    try {
        if (controlType === "thermostat") {
            result = await window.axios.delete(`/v1/thermostat/delete/${controlId}`);
            return result.data.data;
        } else if (controlType === "powercontrol") {
            result = await window.axios.delete(`/v1/powercontrol/delete/${controlId}`);
            return result.data.data;
        } else {
            return null;
        }
    } catch (err) {
        console.log(err);
        return Promise.reject(err);
    }
}

export const getControlsBySite = async (sites) => {
    const ids = sites.map((s) => s.id);
    const controlsResponse = await window.axios.get("/v1/controls/site", { params: { siteId: ids } });
    if (controlsResponse && controlsResponse.data) {
        return controlsResponse.data.data;
    } else {
        return null;
    }
};

export const getSitesWithControls = async () => {
    let result = null
    try {
        result = await window.axios.get('/v1/controls/sites')
        let sites = result.data.data;
        return sites;
    } catch (err) {
        console.error(err);
        return Promise.reject(err)
    }
};

export const setThermostat = async (formData) => {
    let result = null;
    try {
        result = await window.axios.put(`/v1/thermostat/set`, formData);
        return result.data.data;
    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
}


export const setPowerControl = async (formData) => {
    let result = null;
    try {
        result = await window.axios.put(`/v1/powercontrol/set`, formData);
        return result.data.data;
    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
}

export const setBoiler = async (formData) => {
    let result = null;
    try {
        result = await window.axios.put(`/v1/boiler/set`, formData);
        return result.data.data;
    } catch (err) {
        console.error(err);
        return Promise.reject(err);
    }
}
export const mapBoilerUsage = async (boilerControls, sites) => {
    const ids = sites.map((c) => c.id);

    const response = await window.axios.get("/v1/boilers/usage/latest", { params: { id: ids } });

    if (response && response.data && response.data.data) {
        let usages = response.data.data;

        const boilers = boilerControls.map(control => {
            const usage = usages.find(u => u.id === control.id);

            if (usage) {
                control.outsideTemperature = usage.outsideTemperature;
                control.waterTemperature = usage.waterTemperature;
                control.forecastHighTemperature = usage.forecastHighTemperature;
                control.forecastLowTemperature = usage.forecastLowTemperature;
                control.boilerPowerControlStatus = usage.boilerPowerControlStatus;
                control.lockoutMin = usage.lockoutMin;
                control.lockoutMax = usage.lockoutMax;
                control.heatSetting = usage.heatSetting;
                control.minHeatSetting = usage.minHeatSetting;
                control.maxHeatSetting = usage.maxHeatSetting;
                control.coolSetting = usage.coolSetting;
                control.minCoolSetting = usage.minCoolSetting;
                control.maxCoolSetting = usage.maxCoolSettings
                control.fanStatus = usage.fanStatus;
                control.on = usage.on;
                control.frontKeypad = usage.frontKeypad;
                control.mode = usage.mode;
                control.thermostatId = usage.thermostatId;
                control.powerControlId = usage.powerControlId;
                control = mapCommonProps(control, usage); } else {
                    control.waterTemperature = 0;
                } 
            
            return control;
        });

        return boilers;
    }
}

export const mapThermostatUsage = async (thermostatControls, sites) => {
    const ids = sites.map((c) => c.id);

    const response = await window.axios.get("/v1/thermostats/usage/latest", { params: { id: ids } });

    if (response && response.data && response.data.data) {
        let usages = response.data.data;

        const thermostats = thermostatControls.map(control => {
            const usage = usages.find(u => u.controlId === control.id);

            if (usage) {
                control.temperature = usage.temperature;
                control.co2Level = usage.co2Level;
                control.co2Setting = usage.co2Setting;
                control.coolSetting = usage.coolSetting;
                control.heatSetting = usage.heatSetting;
                control.runStatus = usage.runStatus;
                control.fanStatus = usage.fanStatus;
                control.mode = usage.mode;
                control.frontKeypad = usage.frontKeypad;
                control.maxHeatSetting = usage.maxHeatSetting;
                control.maxCoolSetting = usage.maxCoolSetting;
                control.minHeatSetting = usage.minHeatSetting;
                control.minCoolSetting = usage.minCoolSetting;
                control.thermostatId = usage.controlId;

                control = mapCommonProps(control, usage);
            }

            return control;
        });

        return thermostats;
    }
};

export const mapPowerControlUsage = async (powerControls, sites) => {
    const ids = sites.map((c) => c.id);

    const response = await window.axios.get("/v1/powercontrols/usage/latest", { params: { id: ids } });

    if (response && response.data && response.data.data) {
        let usages = response.data.data;

        const controls = powerControls.map(control => {
            const usage = usages.find(u => u.controlId === control.id);

            if (usage) {
                control.on = usage.on;
                control.setBy = usage.setBy;
                control.usageDate = usage.usageDate;
                control.powerControlId = usage.controlId;
                control = mapCommonProps(control, usage);
            }

            return control;
        });

        return controls;
    }
};

export const getControlConfig = async (siteId) => {
  try {
    let result = await window.axios.get(`/v1/controlpermissions/site/${siteId}/scheduler/login`);
    let config = result.data.data;
    // TODO: decrypt response
    return {
        'url': `https://${config.account}.officeclimatecontrol.net/login.cgi`,
        'account': config.account,
        'user': config.userName,
        'pass': config.password
    };
    //return result != undefined ? result.data.data : []; 
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
  }
};


export const getThermostatSystemModes = async () => {
    return ['Heat', 'Cool', 'Auto', 'Off']
};

export const getThermostatFanModes = async () => {
    return ['On', 'Auto']
};

const mapCommonProps = (control, usage) => {
    const usageDate = getLastUsage(usage.usageDateTime);
    let setBy;

    if (usage.setBy) {
        switch (usage.setBy) {
            case "Schedule":
                setBy = "Following Schedule";
                break;
            case "Station":
                setBy = "Local Override";
                break;
            case "Remote":
                setBy = "Software Override";
                break;
            default:
                setBy = usage.setBy;
        }
    }

    return { ...control, usageDate, setBy };
};

const getLastUsage = (usageDate) => {
    //usageDate is in UTC
    //usageDate =   2022-05-17T21:05:00-07:00
    //lastDate =    Tue May 17 2022 21:05:00 GMT-0700 (Pacific Daylight Time)
    var lastDate = new Date(usageDate);

    //get current time in UTC
    // now =    Tue May 17 2022 14:09:33 GMT-0700 (Pacific Daylight Time)
    // utcNow = Tue May 17 2022 21:09:33 GMT-0700 (Pacific Daylight Time)
    var now = new Date(); 
    var nowUtc =  new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(),
                    now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());

    const miliseconds = Math.abs(nowUtc - lastDate);
    let days, total_hours, total_minutes, total_seconds;

    total_seconds = parseInt(Math.ceil(miliseconds / 1000));
    total_minutes = parseInt(Math.ceil(total_seconds / 60));
    total_hours = parseInt(Math.ceil(total_minutes / 60));
    days = parseInt(Math.ceil(total_hours / 24));

    if (miliseconds > 86400000) {
        return days === 1 ? `${days} day ago` : `${days} + days ago`;
    } else if (miliseconds > 3600000) {
        return total_hours === 1
            ? `${total_hours} hour ago`
            : `${total_hours} hours ago`;
    } else {
        return total_minutes === 1
            ? `${total_minutes} minute ago`
            : `${total_minutes} minutes ago`;
    }
}

export default {
    getControlById,
    updateControl,
    deleteControl
}