<template>
  <section>
    <v-row class="fill-height">
      <v-col cols="auto">
        <p class="headline pt-3">
          <v-icon x-large class="pr-2 mt-n2" color="blue">{{
            titleIcon
          }}</v-icon
          >{{ typeName }}
        </p>
      </v-col>

      <v-spacer></v-spacer>
      <v-col cols="auto" md="4" sm="4">
        <v-autocomplete
          :items="sites"
          :item-text="(item) => item.name"
          v-model="selectedSite"
          label="Select site"
          :return-object="true"
          @change="getControlsForSites"
          solo
        ></v-autocomplete>
      </v-col>
      <v-col cols="auto" md="4" sm="4" padding-top="0px">
          <v-text-field
            placeholder="Search controls"
            solo
            hide-details
            v-model="controlSearch"
            clearable
            ><template v-slot:prepend-inner>
              <v-icon>mdi-magnify</v-icon>
            </template></v-text-field>
      </v-col>
    </v-row>
    <v-row v-if="noDevices">
      <v-card
        class="card-center"
        flat
        tile
        style="background-color: #ecf2f6 !important; font-weight: 800"
      >
        No {{ typeName }} configured
      </v-card>
    </v-row>
    <v-row v-if="this.$route.params.type === 'thermostats'">
      <v-col
        v-for="thermostat in thermostats"
        :key="thermostat.id"
        :sm="12"
        :md="6"
        :lg="4"
        :xl="3"
      >
        <!-- <Thermostat :control="thermostat" /> -->
        <ThermostatControlBasic
          @thermostat-set="handleThermostatSet($event)"
          @thermostat-deleted="handleThermostatDeleted"
          :value="thermostat"
          :systemModes="thermostatSystemModes"
          :fanModes="thermostatFanModes"
          :componentKey="thermostat.controlId"
          :isLoading="isLoading"
        />
      </v-col>
    </v-row>

    <v-row v-else-if="this.$route.params.type === 'boilers'">
      <v-col
        v-for="boiler in boilers"
        :key="boiler.id"
        :sm="12"
        :md="6"
        :lg="4"
      >
        <Boiler
          :control="boiler"
          @boiler-set="handleBoilerControlSet($event)"
        />
      </v-col>
    </v-row>

    <v-row v-else-if="this.$route.params.type === 'power-controls'">
      <v-col
        v-for="powerControl in powerControls"
        :key="powerControl.id"
        :sm="12"
        :md="6"
        :lg="4"
      >
        <PowerControl
          :control="powerControl"
          @power-control-deleted="handlePowerControlDeleted"
          @power-control-set="handlePowerControlSet($event)"
        />
      </v-col>
    </v-row>

    <v-row v-else-if="this.$route.params.type === 'batteries'">
      <v-col
        v-for="battery in batteries"
        :key="battery.id"
        :sm="12"
        :md="6"
        :lg="4"
      >
        <Battery :control="battery" />
      </v-col>
    </v-row>
  </section>
</template>

<style>
.card-center {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  height: 25vh;
  width: 50%;
  margin-left: 25%;
}
</style>
<script>
// import Thermostat from "./Thermostat";
import ThermostatControlBasic from "./ThermostatControlBasic";
import Boiler from "./Boiler";
import PowerControl from "./PowerControl";
import Battery from "./Battery";
import { mapGetters, mapActions } from "vuex";
import store from "../_store";

const STORE_KEY = "$_controls";

import {
  getControlsBySite,
  getSitesWithControls,
  mapBoilerUsage,
  mapThermostatUsage,
  mapPowerControlUsage,
  getThermostatFanModes,
  getThermostatSystemModes,
  setThermostat,
  setPowerControl,
  setBoiler,
} from "../_api";

export default {
  components: { ThermostatControlBasic, Boiler, PowerControl, Battery },

  beforeCreate() {
    // if (!this.$store._modules.root._children[STORE_KEY])
    //   this.$store.registerModule(STORE_KEY, store);

    let state = this.$store._modules.root._children[STORE_KEY];
    if (!state) {
      this.$store.registerModule(STORE_KEY, store);
      this.$store.commit(STORE_KEY + "/INITIALIZE_STORE", state);
    }
  },

  async created() {
    this.setTitle(this.$route.params.type);
    this.sites = await getSitesWithControls();
    this.controls = [];
    this.selectedSite = this.getSelectedSite(this.sites);

    if (this.selectedSite !== null) {
      this.getControlsForSites(this.selectedSite);
    }
    this.thermostatFanModes = await getThermostatFanModes();
    this.thermostatSystemModes = await getThermostatSystemModes();
  },

  beforeDestroy() {
    clearInterval(this.polling);
  },

  data: () => {
    return {
      controls: [],
      controlSearch: null,
      sites: [],
      selectedSite: [],
      polling: null,
      typeName: null,
      isRefreshing: false,
      usageReloadTime: 300000, //300 seconds.
      noDevices: true,
      alignment: "center",
      titleIcon: "",
      thermostatSystemModes: [],
      thermostatFanModes: [],
      thermostatKey: "thermostat",
      isLoading: true,
    };
  },

  mounted() {
    //persists any changes to store to local storage
    // this.$store.subscribe((mutation, state) => {
    //   localStorage.setItem(STORE_KEY, JSON.stringify(state[STORE_KEY]));
    // });
  },

  computed: {
    boilers() {
      let self = this;
      let b = this.controls.filter((x) => x.isBoiler);
      //this.setNoDevices("boilers", b.length == 0);
      if (this.controlSearch === null || this.controlSearch === "") {
        return b;
      } else {
        let bf = b.filter((x) => {
          if (x.friendlyName.toLowerCase().includes(self.controlSearch.toLowerCase())) {
            return x;
          }}
        );
        return bf;
      }
    },
    thermostats() {
      let self = this;
      let t = this.controls.filter(
        (x) => !x.isBoiler && x.controlTypeName === "Thermostat"
      );    
      //this.setNoDevices("thermostats", t.length == 0);
      if (this.controlSearch === null || this.controlSearch === "") {
        return t;
      } else {
        let tf = t.filter((x) => {
          if (x.friendlyName.toLowerCase().includes(self.controlSearch.toLowerCase())) {
            return x; 
          }}          
        );
        return tf;
      }
    },
    powerControls() {
      let self = this;
      let pc = this.controls.filter(
        (x) => !x.isBoiler && x.controlTypeName === "PowerControl"
      );
      //this.setNoDevices("powerControls", pc.length == 0);
      if (this.controlSearch === null || this.controlSearch === "") {
        return pc;
      } else {
        let pcf = pc.filter((x) => {
          if (x.friendlyName.toLowerCase().includes(self.controlSearch.toLowerCase())) {
            return x;
          }}
        );
        return pcf;
      }
    },
    batteries() {
      let getRandomInt = (min, max) => {
        min = Math.ceil(min);
        max = Math.floor(max);
        return Math.floor(Math.random() * (max - min + 1)) + min;
      };

      let dummyData = [];
      for (let i = 1; i < 3; i++) {
        let capacity = getRandomInt(200, 400);
        dummyData.push({
          id: i,
          name: `battery-control-${i}`,
          friendlyName: `Battery #${i}`,
          description: "This is the description of this battery",
          site: { id: 1, name: "85 Argonaut" },
          data: {
            capacity: capacity,
            currentCapacity: getRandomInt(20, capacity),
            dischargeRate: getRandomInt(-50, 50),
            peakDischargeRate: getRandomInt(40, 70),
            stackVoltage: getRandomInt(430, 580),
            stackTemperature: getRandomInt(20, 35),
            peakCurrent: getRandomInt(25, 35),
          },
        });
      }
      return dummyData;
    },
    ...mapGetters({
      defaultSite: "session/defaultSite",
      lastUpdated: STORE_KEY + "/lastUpdated",
      refreshedControls: STORE_KEY + "/refreshedControls",
    }),
  },
  watch: {
    "$route.params.type": function (type) {
      this.setTitle(type);
      this.getControlsForSites(this.selectedSite);
    },
  },
  methods: {
    ...mapActions({
      setDefaultSite: "session/setDefaultSite",
      updateControl: STORE_KEY + "/updateControl",
      refreshControls: STORE_KEY + "/refreshControls",
    }),

    getSelectedSite(sites) {
      let selectedSite = {};

      const routeSiteId = parseInt(this.$route.params.siteId);

      if (this.$route.params.siteId) {
        selectedSite = sites.find((s) => {
            return s.id === routeSiteId;
        });
      } else {
        selectedSite = this.defaultSite;
      }
      return selectedSite;
    },

    setNoDevices(value) {
      this.noDevices = value;
    },

    async handleThermostatSet(thermostat) {
      console.log(`thermostatHeatSetting: ${thermostat.heatSetting}`);
      this.updateControl(thermostat); //state
      try {
        await setThermostat(thermostat);
        this.$toast.show(`${thermostat.name} set.`, null, "success");
      } catch (error) {
        this.$toast.show(error, null, "error");
      }
    },

    async handlePowerControlSet(powerControl) {
      console.log(`powercontrolsettings: ${powerControl.output}`);
      this.updateControl(powerControl);
      try {
        await setPowerControl(powerControl);
        this.$toast.show(`${powerControl.name} set.`, null, "success");
      } catch (error) {
        this.$toast.show(error, null, "error");
      }
    },

    async handleBoilerControlSet(boilerControl) {
      console.log(`boilercontrolsettings: ${boilerControl.heatSetting}`);
      this.updateControl(boilerControl);
      try {
        await setBoiler(boilerControl);
        this.$toast.show(`${boilerControl.name} set.`, null, "success");
      } catch (error) {
        this.$toast.show(error, null, "error");
      }
    },

    async getControlsForSites(site) {
      let self = this;
      this.isLoading = true;
      this.setNoDevices(false);
      this.controls = await getControlsBySite([site]);
      await this.loadLatestUsage(this.$route.params.type);
      this.isLoading = false;
      clearInterval(this.polling);
      this.polling = setInterval(async () => {
        self.isRefreshing = true;
        this.controls = await getControlsBySite([site]);
        await this.loadLatestUsage(this.$route.params.type);
      }, this.usageReloadTime);
      this.setDefaultSite(site);
    },

    async loadLatestUsage(type) {
      if (!this.controls) {
        this.noDevices = true;
        return;
      }

      if (type) {
        if (type === "boilers") {
          const boilers = await mapBoilerUsage(this.boilers, [
            this.selectedSite,
          ]);
          this.setNoDevices(boilers.length == 0);
          this.replaceControls(boilers, type);
          // payload = { updated: timestamp, controls: this.boilers, type: type };
        } else if (type === "thermostats") {
          const thermostats = await mapThermostatUsage(this.thermostats, [
            this.selectedSite,
          ]);
          this.setNoDevices(thermostats.length == 0);
          this.replaceControls(thermostats, type);
          // payload = { updated: timestamp, controls: this.thermostats, type: type };
        } else if (type === "power-controls") {
          const powerControls = await mapPowerControlUsage(this.powerControls, [
            this.selectedSite,
          ]);
          this.setNoDevices(powerControls.length == 0);
          this.replaceControls(powerControls, type);
          // payload = { updated: timestamp, controls: this.powerControls, type: type };
        }
      } else {
        //map all controls
        const boilers = await mapBoilerUsage(this.boilers, [this.selectedSite]);
        const thermostats = await mapThermostatUsage(this.thermostats, [
          this.selectedSite,
        ]);
        const powerControls = await mapPowerControlUsage(this.powerControls, [
          this.selectedSite,
        ]);
        // payload = { updated: timestamp, controls: [...boilers, ...thermostats, ...powerControls],
        // type: "controls"}
        this.replaceControls(
          [...boilers, ...thermostats, ...powerControls],
          "controls"
        );
      }
    },

    replaceControls(controlsToRefresh, type) {
      //replace the updated controls from server with cached usagage if these have not expired
      //updatedControls = getCachedControlUsages(updatedControls)
      const timestamp = Date.now();
      let payload = {
        updated: timestamp,
        controls: controlsToRefresh,
        type: type,
      };
      this.refreshControls(payload);

      //let refreshedControls = this.refreshedControls; //getter from the state
      let typedControls = this.refreshedControls.find((c) => c.type === type);

      const newControls = typedControls.controls.map((c) => {
        const index = controlsToRefresh.findIndex((x) => x.id === c.id);

        if (index < 0) {
          return controlsToRefresh[index];
        } else {
          return c;
        }
      });
      //TODO: set control state here
      this.controls = newControls;
    },    

    handleThermostatDeleted() {
      this.getControlsForSites(this.selectedSite);
    },

    handlePowerControlDeleted() {
      this.getControlsForSites(this.selectedSite);
    },

    setTitle(type) {
      switch (type) {
        case "thermostats":
          this.typeName = "Thermostats";
          this.titleIcon = "thermostat";
          break;
        case "power-controls":
          this.typeName = "Power Controls";
          this.titleIcon = "toggle_on";
          break;
        case "boilers":
          this.typeName = "Boilers";
          this.titleIcon = "whatshot";
          break;
        case "batteries":
          this.typeName = "Batteries";
          this.titleIcon = "fa-battery-three-quarters";
          break;
        default:
          this.typeName = "All Controls";
          break;
      }
    },
  },
};
</script>