<template>
  <v-dialog v-model="showConfirm" :width="width">
    <v-card>
      <v-card-title class="headline">{{ currentTitle }}</v-card-title>
      <v-card-text v-html="currentMessage"></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn @click="handleCancel" text colr="blue darken-1">Cancel</v-btn>

        <v-btn @click="handleOk" text color="blue darken-1">Ok</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style>
  .v-card__text, .v-card__title {
    word-break: normal !important;
  }
</style>
<script>
export default {
  props: {
    title: { type: String, default: "Please Confirm" },
    message: { type: String, default: "Are you sure you want to do this?" },
    width: { type: String, default: "290" }
  },
  data() {
    return {
      showConfirm: false,
      currentTitle: this.title,
      currentMessage: this.message,
    };
  },
  watch: {
    show(newVal) {
      this.showConfirm = newVal;
    }
  },
  methods: {
    async confirm(message, title) {
      this.showConfirm = true;
      
      if(title){
        this.currentTitle = title;
      }

      if(message){
        this.currentMessage = message;
      }

      let promise = new Promise((resolve) => {
        this.$on("ok", () => {
          this.showConfirm = false;
          return resolve(true);
        });
        this.$on("cancel", () => {
           this.showConfirm = false;
          return resolve(false);
        });
      });

      return promise;
    },
    handleOk() {
      this.$emit("ok");
      this.$parent.$emit("ok");
      this.showConfirm = false;

    },
    handleCancel() {
      this.$emit("cancel");
      this.$parent.$emit("cancel");
      this.showConfirm = false;
    }
  }
};
</script>