<template>
  <div>
    <div class="set-by-container">
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <span
            v-on="on"
            class="grey--text text--darken-1 text-right font-weight-light caption"
          >{{ setBy }}</span>
        </template>
        <span>Set By</span>
      </v-tooltip>
    </div>
    <v-tooltip bottom>
      <template v-slot:activator="{ on }">
        <span
          v-on="on"
          class="grey--text text--darken-1 text-right font-weight-light caption"
        >{{ lastUpdated }}</span>
      </template>
      <span>Last Updated</span>
    </v-tooltip>
  </div>
</template>

<script>
export default {
  name: "LastUpdated",
  props: {
    setBy: { type: String },
    lastUpdated: { type: String }
  }
};
</script>
<style scoped>
.set-by-container {
  line-height: 1em;
}
</style>