<template>
  <section class="d-flex">
    <schedule-action :controlId="controlId" :controlType="controlType" :siteId="siteId"></schedule-action>
  </section>
</template>

<script>
import ScheduleAction from "./ScheduleAction";

export default {
  components: {
    "schedule-action": ScheduleAction
  },
  props: {
    controlId: { type: Number, required: true },
    siteId: { type: Number, required: true }
  },
  computed: {
    controlType(){
      return this.$route.params.type;
    }
  },
};
</script>