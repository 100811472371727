<template>
  <v-row>
    <v-col :cols="9">
      <v-slider
        :title="`max value: ${max}; min value: ${min}`"
        v-model="initialValue"
        :label="label"
        :color="sliderColor"
        :min="min"
        :max="max"
        @change="handleChange"
        thumb-label
      ></v-slider>
    </v-col>
    <v-col>
      <v-text-field
        v-model="initialValue"
        class="mt-0"
        hide-details
        single-line
        type="number"
        @input="handleChange"
        :rules="[rangeRule]"
      ></v-text-field>
    </v-col>
  </v-row>
</template>
<script>
export default {
  props: {
    label: { type: String },
    min: { type: Number, default: 0 },
    max: { type: Number, default: 100 },
    value: [String, Number],
    sliderColor: { type: String, default: "blue" },
    isLoading: { type: Boolean, default: true }
  },
  computed: {
    isDirty() {
      return this.initialValue !== this.startValue;
    },
  },
  data() {
    return {
      startValue: this.value,
      initialValue: this.value,
      rangeRule: (r) => {
        if (!isNaN(parseFloat(r)) && r >= this.min && r <= this.max) {
          this.$emit("temperature-validation-message", "");
          return true;
        } else {
          this.handleChange(r);
          if (!this.isLoading) {
            this.$emit(
              "temperature-validation-message",
              `Setting must be a number between ${this.min} and ${this.max}`
            );
          }
          return false;
        }
      },
    };
  },
  watch: {
    value: function (newVal) {
      this.startValue = Math.round(newVal);
      this.initialValue = Math.round(newVal);
    },
  },
  methods: {
    handleChange(val) {
      val = Number(val);
      this.initialValue = val;
      this.$emit("ontemperaturechange", val);
    },
  },
};
</script>