<template>
  <v-hover>
    <v-card
      slot-scope="{ hover }"
      :class="`elevation-${hover ? 8 : 2}`"
      class="mx-auto"
    >
      <v-card-title class="blue-grey lighten-4">
        <v-row no-gutters>
          <v-col :cols="10">
            <div class="title text--primary text-truncate" style="width: 100%">
              {{ control.friendlyName }}
            </div>

            <div
              class="subtitle-1 text--secondary font-weight-light"
              style="width: 100%"
            >
              {{ control.site.name }}
            </div>
          </v-col>
          <v-col align-self="center">
            <v-icon color="blue" class="float-right" large>whatshot</v-icon>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text>
        <Loader :isLoading="control.waterTemperature == null">
          <Label label="Hot Water Temperature" v-if="control.waterTemperature!==0">
            <div
              :class="classTemperatureColor(control.waterTemperature)"
              class="display-2"
            >
              {{ control.waterTemperature }}&deg;
            </div>
          </Label>
          <Label label="Hot Water Temperature" v-else>
            <div class="display-1">No data available.</div>
          </Label>

          <Label label="Outside Air Temperature">
            <div
              :class="classTemperatureColor(control.outsideTemperature)"
              class="display-2"
            >
              {{ control.outsideTemperature }}&deg;
            </div>
          </Label>

          <v-divider />

          <Label
            label="Forecast Outside High Temperature"
            single-line
            :labelLarge="true"
          >
            <!-- {{ parseInt(control.forecastHighTemperature) }}&deg; -->
            <p v-if="control.forecastHighTemperature == 0">Not Available</p>
            <p v-else>{{ parseInt(control.forecastHighTemperature) }}&deg;</p>
          </Label>

          <Label
            label="Forecast Outside Low Temperature"
            single-line
            :labelLarge="true"
          >
            {{ parseInt(control.forecastLowTemperature) }}&deg;
          </Label>

          <Label label="Boiler Power Status" single-line :labelLarge="true">
            <v-icon
              small
              :color="
                control.boilerPowerControlStatus === 'On'
                  ? 'success'
                  : 'default'
              "
              class="mb-1"
              >lens</v-icon
            >
          </Label>

          <!-- <Label label="Set Lockout Temperature">
              <Temperature
                :value="control.heatSetting"
                :hot="90"
                :cool="65"
                :requireSet="true"
                :min="control.minHeatSetting"
                :max="control.maxHeatSetting" /> -->

          <TemperatureSlider
            label="Lockout Temperature"
            :value="control.heatSetting"
            @temperature-validation-message="onTemperatureSet($event)"
            @ontemperaturechange="onHeatSettingChange($event)"
            :min="control.minHeatSetting"
            :max="control.maxHeatSetting"
          />
          <!-- 
            </Label> -->

          <Label label="Last Updated" single-line :labelLarge="true">
            <LastUpdated
              :setBy="control.setBy"
              :lastUpdated="control.usageDate"
            />
          </Label>
        </Loader>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <ActionBar :controlId="control.id" :siteId="control.site.id"/>
        <v-spacer></v-spacer>
        <v-btn v-if="isDirty" color="green darken-1" text @click="onSubmit"
          >Set</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-hover>
</template>


<script>
import TemperatureSlider from "./TemperatureSlider";
import LastUpdated from "./LastUpdated";
import ActionBar from "./ActionBar";
import Loader from "./Loader";
import Label from "./Label";

export default {
  components: {
    TemperatureSlider,
    LastUpdated,

    ActionBar,
    Loader,
    Label,
  },
  props: {
    control: { type: Object, required: true },
  },
  data() {
    return {
      isValid: true,
      isDirty: false,
      isLoading: true,
      currentControl: {},
    };
  },
  computed: {
    currentMode() {
      if (this.control.runStatus) {
        return this.splitPascalCase(this.control.runStatus);
      }

      return "";
    },
  },
  watch: {
    deep: true,
    control: function (newVal) {
      this.currentControl = newVal;
    },
    "currentControl.waterTemperature": function(newVal) {
      console.log(`waterTemperature: ${newVal}`)
    }
  },
  methods: {
    classTemperatureColor(value) {
      if (value > 100) return "red--text";
      else if (value < 50) return "blue--text";
      else return "blue--text darken-2";
    },
    classMode(value) {
      if (value.indexOf("Cool") >= 0) {
        return "blue--text";
      } else if (value.indexOf("Heat") >= 0) {
        return "red--text";
      }

      return "grey--text text--darken-1";
    },
    onTemperatureSet(message) {
      this.isValid = message.length === 0;
      if (!this.isValid) {
        this.$toast.show(message, null, "error");
      }
    },
    onHeatSettingChange(value) {
      this.currentControl.heatSetting = value;
      this.isDirty = true;
    },
    onSubmit() {
      this.currentControl.setBy = "Manual override";
      this.currentControl.usageDate = "Just now";
      this.$emit("boiler-set", this.currentControl);
      this.isDirty = false;
    },
    splitPascalCase(word) {
      return word.replace(/([A-Z][a-z])/g, " $1").replace(/(\d)/g, " $1");
    },
  },
};
</script>

<style >
.display-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: space-between;
}
.display-row {
  display: flex;
  justify-content: space-between;
}
.display-row span:first-child {
  margin-right: 3px;
}
.v-application--is-ltr .v-input__slider .v-input__slot .v-label {
  font-weight: 700 !important;
}
</style>