<template>
  <v-hover>
    <v-card
      slot-scope="{ hover }"
      :class="`elevation-${hover ? 8 : 2}`"
      class="mx-auto"
      dense
    >
      <v-card-title class="blue-grey lighten-4">
        <v-row no-gutters>
          <v-col :cols="10">
            <div class="title text--primary text-truncate" style="width: 100%">
              {{ currentControl.friendlyName }}
            </div>
            <div
              class="subtitle-1 text--secondary font-weight-light"
              style="width: 100%"
            >
              {{ currentControl.siteLocationName }}
            </div>
          </v-col>
          <v-col align-self="center">
            <v-icon color="blue" class="float-right" large>thermostat</v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider />
      <v-card-text dense>
        <Loader
          :isLoading="
            currentControl.temperature === null ||
            currentControl.temperature == undefined
          "
        >
          <v-row v-if="hasCO2" no-gutters>
            <v-col cols="12" md="8">
              <Label label="Temperature" :singleLine="true" :labelLarge="true">
                <div class="display-2 blue--text darken-2">
                  {{ currentControl.temperature }}&deg;
                </div>
              </Label></v-col
            >
            <v-col md="1"><v-divider vertical class="pl-3"></v-divider></v-col>
            <v-col md="3">
              <Label
                label="CO2"
                :singleLine="false"
                :labelLarge="true"
                :valueIndent="false"
                class="mt-3"
              >
                <v-tooltip bottom
                  ><template v-slot:activator="{ on, attrs }">
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="green--text darken-2 co2-warning"
                      v-if="!co2Warning"
                    >
                      {{ currentControl.co2Level }} ppm
                    </div>
                    <div
                      v-bind="attrs"
                      v-on="on"
                      class="red--text darken-2 co2-warning"
                      v-else
                    >
                      {{ currentControl.co2Level }} ppm
                    </div>
                  </template>
                  <span>{{ co2SettingText }}</span></v-tooltip
                >
              </Label>
            </v-col>
          </v-row>

          <Label
            v-else
            label="Temperature"
            :singleLine="true"
            :labelLarge="true"
          >
            <div class="display-2 blue--text darken-2">
              {{ currentControl.temperature }}&deg;
            </div>
          </Label>

          <Label
            label="Status"
            :value="currentStatus"
            :singleLine="true"
            :labelLarge="true"
          />
          <TemperatureSlider
            label="Heat Setting"
            sliderColor="red"
            :value="currentControl.heatSetting"
            @temperature-validation-message="onTemperatureSet($event)"
            @ontemperaturechange="onHeatSettingChange($event)"
            :min="currentControl.minHeatSetting"
            :max="currentControl.maxHeatSetting"
            :isLoading="isLoading"
          />
          <v-range-slider
            v-model="heatRange"
            :max="heatMax"
            :min="heatMin"
            thumb-label
            thumb-color="red"
            readonly
            dense
          >
            <template v-slot:prepend>
              <v-label class="min-label">Min: {{ heatRange[0] }}</v-label>
            </template>
            <template v-slot:append>
              <v-label class="min-label">Max: {{ heatRange[1] }}</v-label>
            </template>
          </v-range-slider>

          <TemperatureSlider
            label="Cool Setting"
            :value="currentControl.coolSetting"
            @temperature-validation-message="onTemperatureSet($event)"
            @ontemperaturechange="onCoolSettingChange($event)"
            :min="currentControl.minCoolSetting"
            :max="currentControl.maxCoolSetting"
            :isLoading="isLoading"
          />
          <v-range-slider
            v-model="coolRange"
            :max="coolMax"
            :min="coolMin"
            thumb-label
            readonly
            thumb-color="#2196F3"
            dense
          >
            <template v-slot:prepend>
              <v-label class="min-label">Min: {{ coolRange[0] }}</v-label>
            </template>
            <template v-slot:append>
              <v-label class="min-label">Max: {{ coolRange[1] }}</v-label>
            </template>
          </v-range-slider>
          <v-row>
            <v-col
              ><v-select
                label="System"
                :items="systemModes"
                v-model="currentControl.mode"
                @change="onSystemChange"
              ></v-select
            ></v-col>
            <v-col>
              <v-select
                label="Fan"
                :items="fanModes"
                v-model="currentControl.fanStatus"
                @change="onFanChange"
              ></v-select
            ></v-col>
          </v-row>

          <Label label="Last Updated" single-line :labelLarge="true">
            <LastUpdated
              :setBy="currentControl.setBy"
              :lastUpdated="currentControl.usageDate"
            />
          </Label>
        </Loader>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn icon @click="toggleLock">
          <v-icon v-if="locked">lock</v-icon>
          <v-icon v-if="!locked">lock_open</v-icon>
        </v-btn>
        <ActionBar
          :controlId="currentControl.id"
          :siteId="currentControl.site.id"
        />
        <v-btn icon @click="goToEdit(currentControl.id)">
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn icon @click="onControlDelete(currentControl)">
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          v-if="isDirty"
          color="green darken-1"
          :disabled="!isValid"
          text
          @click="onSubmit"
          >Set</v-btn
        >
      </v-card-actions>
      <confirm-dialog
        ref="confirmDelete"
        title="Delete Control?"
      ></confirm-dialog>
    </v-card>
  </v-hover>
</template>
<style scoped>
.v-input__prepend-outer label,
.v-input__append-outer label {
  width: 80px !important;
}
.co2-warning {
  cursor: pointer;
}
</style>
<script>
import "vue-datetime/dist/vue-datetime.css";

import TemperatureSlider from "./TemperatureSlider";
import Label from "./Label";
import Loader from "./Loader";
import LastUpdated from "./LastUpdated";
import ActionBar from "./ActionBar";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import api from "../_api";

export default {
  components: {
    TemperatureSlider,
    Label,
    Loader,
    LastUpdated,
    ActionBar,
    "confirm-dialog": ConfirmDialog,
  },
  props: {
    componentKey: [Number, String],
    systemModes: { type: Array },
    fanModes: { type: Array },
    value: { type: Object, required: true },
    isLoading: { type: Boolean, default: true },
  },
  data() {
    return {
      isValid: true,
      currentControl: this.value,
      gotDirty: false,
      isLocked: false,
      coolRange: [],
      coolMin: "60",
      coolMax: "90",
      heatRange: [],
      heatMin: "60",
      heatMax: "90",
      hasCO2: false,
      co2Warning: false,
      co2SettingText: null,
    };
  },
  mounted() {},
  computed: {
    currentStatus() {
      if (this.currentControl.runStatus) {
        return this.splitPascalCase(this.currentControl.runStatus);
      }
      return "";
    },
    isDirty: {
      get: function () {
        return this.gotDirty;
      },
      set: function (newVal) {
        this.gotDirty = newVal;
      },
    },
    locked: {
      get: function () {
        return this.isLocked;
      },
      set: function (newVal) {
        this.isLocked = newVal;
      },
    },
  },
  watch: {
    deep: true,
    value: function (newVal) {
      this.currentControl = newVal;
      this.isLocked = newVal.frontKeypad === "Off" ? true : false;
      this.heatRange = [newVal.minHeatSetting, newVal.maxHeatSetting];
      this.coolRange = [newVal.minCoolSetting, newVal.maxCoolSetting];
      this.setCO2Display();
    },
    coolRange: function (newVal, oldVal) {
      if (oldVal.length === 0) return;
      this.currentControl.minCoolSetting = newVal[0];
      this.currentControl.maxCoolSetting = newVal[1];
    },
    heatRange: function (newVal, oldVal) {
      if (oldVal.length === 0) return;
      this.currentControl.minHeatSetting = newVal[0];
      this.currentControl.maxHeatSetting = newVal[1];
    },
  },
  methods: {
    toggleLock() {
      this.currentControl.frontKeypad =
        this.currentControl?.frontKeypad === "Off" ? "On" : "Off";
      this.isLocked = this.currentControl.frontKeypad === "Off";
      this.gotDirty = true;
    },
    onSubmit() {
      console.log(this.currentControl);
      this.currentControl.setBy = "Manual override";
      this.currentControl.usageDate = "Just now";
      this.gotDirty = false;
      this.onChange();
    },
    goToEdit(controlId) {
      this.$router.push({
        name: "ControlsEdit",
        params: { type: "thermostat", controlId: controlId },
      });
    },
    onChange() {
      this.$emit("thermostat-set", this.currentControl); //TODO: handler in parent will take care of managing the state
    },
    onTemperatureSet(message) {
      this.isValid = message.length === 0;
      if (!this.isValid) {
        this.$toast.show(message, null, "error");
      }
    },
    onHeatSettingChange(value) {
      this.currentControl.heatSetting = value;
      this.gotDirty = true;
    },
    onCoolSettingChange(value) {
      this.currentControl.coolSetting = value;
      this.gotDirty = true;
    },
    onSystemChange() {
      this.gotDirty = true;
    },
    onFanChange() {
      this.gotDirty = true;
    },
    async onControlDelete(currentControl) {
      console.log(currentControl);
      const msg =
        `Are you sure you want to delete ${currentControl.friendlyName} and its associated data?  Deleting a control cannot be undone.`;
      const confirmed = await this.$refs.confirmDelete.confirm(msg);

      if (!confirmed) {
        return;
      }

      await api.deleteControl("thermostat", currentControl.id);
      this.$emit("thermostat-deleted", this.currentControl);

    },
    splitPascalCase(word) {
      return word.replace(/([A-Z][a-z])/g, " $1").replace(/(\d)/g, " $1");
    },

    setCO2Display() {
      this.hasCO2 = this.currentControl.co2Level != null;

      if (this.hasCO2) {
        if (this.currentControl.co2Level > this.currentControl.co2Setting) {
          this.co2Warning = true;
          this.co2SettingText = `Current reading ${this.currentControl.co2Level} ppm exceeds max CO2 setting of ${this.currentControl.co2Setting} ppm`;
        } else {
          this.co2Warning = false;
          this.co2SettingText = `Current reading ${this.currentControl.co2Level} ppm is less than max CO2 setting of ${this.currentControl.co2Setting} ppm`;
        }
      }
    },
  },
};
</script>

