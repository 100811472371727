<template>
  <v-hover>
    <v-card
      slot-scope="{ hover }"
      :class="`elevation-${hover ? 8 : 2}`"
      class="mx-auto"
    >
      <v-card-title class="blue-grey lighten-4">
        <v-row no-gutters>
          <v-col :cols="10">
            <div class="title text--primary text-truncate" style="width: 100%">
              {{ currentControl.friendlyName }}
            </div>

            <div
              class="subtitle-1 text--secondary font-weight-light"
              style="width: 100%"
            >
              {{ currentControl.siteLocationName }}
            </div>
          </v-col>
          <v-col align-self="center">
            <v-icon color="blue" class="float-right" large>toggle_on</v-icon>
          </v-col>
        </v-row>
      </v-card-title>

      <v-divider />

      <v-card-text>
        <Loader :isLoading="currentControl.on == null">
          <Label single-line label="Is Running Schedule">
            <v-icon small :color="scheduleLampColor" class="mb-1">lens</v-icon>
          </Label>

          <v-divider class="my-3" />

          <v-switch
            v-model="currentControl.on"
            @change="updateSwitchStatus"
            :label="switchStatusText"
            color="success"
            hide-details
          />

          <Label label="Last Updated" single-line>
            <LastUpdated
              :setBy="control.setBy"
              :lastUpdated="control.usageDate"
            />
          </Label>
        </Loader>
      </v-card-text>

      <v-divider />
      <v-card-actions>
        <ActionBar :controlId="control.id" :siteId="control.site.id" />
        <v-btn icon @click="goToEdit(currentControl.id)">
          <v-icon>edit</v-icon>
        </v-btn>
        <v-btn icon @click="onControlDelete(currentControl)">
          <v-icon>mdi-delete-outline</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn v-if="isDirty" color="green darken-1" text @click="onSubmit"
          >Set</v-btn
        >
      </v-card-actions>
      <confirm-dialog
        ref="confirmDelete"
        title="Delete Control?"
      ></confirm-dialog>
    </v-card>
  </v-hover>
</template>

<script>
import LastUpdated from "./LastUpdated";
import ActionBar from "./ActionBar";
import Loader from "./Loader";
import Label from "./Label";
import ConfirmDialog from "@/components/Dialogs/ConfirmDialog";
import api from "../_api";

export default {
  components: {
    Label,
    LastUpdated,
    ActionBar,
    Loader,
    "confirm-dialog": ConfirmDialog,
  },
  props: {
    control: { type: Object, required: true },
  },

  data() {
    return {
      gotDirty: false,
      currentControl: {},
      controlOn: "Off",
    };
  },
  watch: {
    deep: true,
    control: function (newVal) {
      this.currentControl = newVal;
      this.switchStatusText = newVal.on;
    },
  },
  computed: {
    switchStatusText: {
      get: function () {
        return this.controlOn;
      },
      set: function (newVal) {
        this.controlOn = newVal ? "On" : "Off";
      },
    },
    scheduleLampColor() {
      return this.control.setBy === "Following Schedule"
        ? "success"
        : "default";
    },
    isDirty: {
      get: function () {
        return this.gotDirty;
      },
      set: function (newVal) {
        this.gotDirty = newVal;
      },
    },
  },

  methods: {
    updateSwitchStatus(newVal) {
      //set is dirty and emit message
      this.switchStatusText = newVal;
      this.gotDirty = true;
    },
    onSubmit() {
      this.currentControl.setBy = "Manual override";
      this.currentControl.usageDate = "Just now";
      this.currentControl.output = this.controlOn;
      this.$emit("power-control-set", this.currentControl); //TODO: handler in parent will take care of managing the state
      this.gotDirty = false;
    },
    goToEdit(controlId) {
      this.$router.push({
        name: "ControlsEdit",
        params: { type: "powercontrol", controlId: controlId },
      });
    },

    async onControlDelete(currentControl) {
      console.log(currentControl);
      const msg = `Are you sure you want to delete ${currentControl.friendlyName} and its associated data?  Deleting a control cannot be undone.`;
      const confirmed = await this.$refs.confirmDelete.confirm(msg);

      if (!confirmed) {
        return;
      }

      await api.deleteControl("powercontrol", currentControl.id);
      this.$emit("power-control-deleted", this.currentControl);
    },
  },
};
</script>
