<template>
  <!-- <v-menu v-model="scheduleMenu" :close-on-content-click="false" :nudge-width="200" offset-x> -->
  <!-- <template v-slot:activator="{ on }"> -->
  <!-- <v-btn icon v-on="on"> -->
  <section>
    <v-btn icon @click="openScheduleDashboard">
      <v-icon>calendar_today</v-icon>
    </v-btn>
    <confirm-dialog
      ref="confirmOpen"
      width="450"
      title="Open Control Configuration Page?"
    ></confirm-dialog>
  </section>
</template> 

<script>
import { getControlConfig } from "../_api";
import ConfirmDialog from "../../../components/Dialogs/ConfirmDialog";

export default {
  components: {
    "confirm-dialog": ConfirmDialog,
  },
  props: {
    controlId: { type: Number, required: true },
    controlType: { type: String, required: true },
    siteId: { type: Number, required: true },
  },
  data() {
    return {
      scheduleMenu: false,
      sharedSchedules: [],
      currentScheduleId: null,
      initialScheduleId: null,
      isLoading: false,
      isSaving: false,
    };
  },
  computed: {
    isDirty() {
      return this.currentScheduleId !== this.initialScheduleId;
    },
  },

  async mounted() {},

  methods: {
    async openScheduleDashboard() {
      let data = await getControlConfig(this.siteId);
      console.log(data);
      if (!data) {
        this.$toast.show(
          "You do not have control permission for scheduling.  Please contact your account or administrator Intech Support to gain access to scheduling",
          10000, "error"
        );
        return;
      }

      console.log(`SiteId: ${this.siteId} maps to domain ${data.account}.`);
      const msgHtml =
        `Will now open Pelican Management Site for the current site, <em>${data.account}</em>, in a new window or tab.` +
        "<br/><br/>" +
        "Please wait for the page to load, then select the <br/>" +
        "<strong style='background: #2988c8; color: white; padding: 4px'><large>Schedule Dashboard</large></strong> " +
        "tile (blue background, fourth from the top) to view or configure your schedules.";

      let confirmed = await this.$refs.confirmOpen.confirm(msgHtml);
      if (!confirmed) {
        return;
      }

      const windowOptions =
        "modal=yes, toolbar=no, location=no, directories=no, status=no, menubar=no," +
        "height=800,width=600";

      this.openWindowWithPost(data.url, windowOptions, `dash_${data.account}`, {
        username: data.user,
        password: data.pass,
      });
    },
    openWindowWithPost(url, windowoption, name, params) {
      var form = document.createElement("form");
      form.setAttribute("method", "post");
      form.setAttribute("action", url);
      form.setAttribute("target", name);
      for (var i in params) {
        var input = document.createElement("input");
        input.type = "hidden";
        input.name = i;
        input.value = params[i];
        form.appendChild(input);
      }
      document.body.appendChild(form);
      window.open("about:blank", name, windowoption);
      form.submit();
      document.body.removeChild(form);
    },
  },
};
</script>