<template>
  <section>
    <slot v-if="!isLoading"></slot>
    <v-progress-circular v-else color="primary" :indeterminate="true"></v-progress-circular>
  </section>
</template>

<script>
export default {
  name: 'Loader',
  props: {
    isLoading: { type: Boolean, required: true }
  }
}
</script>