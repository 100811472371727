
// const setControls = async (context, payload) => {
//   context.commit('CONTROLS_INITIALIZED', payload);
// };

const refreshControls = async (context, controls) => {
  context.commit('CONTROLS_REFRESHED', controls)
};

const updateControl = async (context, control) => {
  context.commit('CONTROL_UPDATED', control);
};

export default {
  updateControl,
  refreshControls
};